import React from "react"
import ItemDeal from "./ItemDeal"
import { useStaticQuery, graphql } from "gatsby"

const ListDeal = ({ container = false, className = null }) => {


    const deals = useStaticQuery(graphql`
    query {
        allDatoCmsMattress(
            filter: {locale: {eq: "it"}, location: {websiteUrl: {eq: "it.mattress.zone"}}}
            sort: {fields: name, order: ASC}
        ) {
            nodes {
                name
                slug
                location {
                    websiteUrl
                }
                mattressImage {
                    gatsbyImageData(
                        imgixParams: {auto: "format", fit: "crop", h: "30", w: "100", colors: 10}
                        placeholder: BLURRED
                    )
                }
                id
                brand {
                    brandName
                    promotions {
                        startDate
                        endDate
                        description
                        affiliateLinkCta
                        altTitle
                        affiliateLinkPromotion {
                            affiliateLink
                            shortenedAffiliateLink
                        }
                    }
                }
                promotions {
                    startDate
                    endDate
                    description
                    affiliateLinkCta
                    altTitle
                    affiliateLinkPromotion {
                        affiliateLink
                        shortenedAffiliateLink
                    }
                }
            }
        }
    }
    `)

    const promotions = []
    const todayDate = new Date()
    deals.allDatoCmsMattress.nodes.forEach((mattress, i) => {
        const promotionsBrand = mattress.brand.promotions
        if (promotionsBrand.length) {
            promotionsBrand.forEach((promotion, j) => {
                const startDate = promotion.startDate ? promotion.startDate : null
                const flag = startDate && startDate.getTime() > todayDate.getTime() ? false : true

                if (flag) {
                    promotions.push(
                        <ItemDeal
                            link={promotion.affiliateLinkPromotion.shortenedAffiliateLink ? promotion.affiliateLinkPromotion.shortenedAffiliateLink : promotion.affiliateLinkPromotion.affiliateLink}
                            image={mattress.mattressImage}
                            title={mattress.name}
                            cta={promotion.affiliateLinkCta}
                            description={promotion.description}
                            key={`key-deal-${i}-${j}`} 
                            headerClass={`${ i % 2 === 0 ? 'deal-bg-1' : 'deal-bg-2'}`}
                        />
                    )
                }
            })
        } else {
            const promotionsMattress = mattress.promotions
            if (promotionsMattress.length) {
                promotionsMattress.forEach((promotion, k) => {
                    const startDate = promotion.startDate ? promotion.startDate : null
                    const flag = startDate && startDate.getTime() > todayDate.getTime() ? false : true

                    if (flag) {
                        promotions.push(
                            <ItemDeal
                                link={promotion.affiliateLinkPromotion.shortenedAffiliateLink ? promotion.affiliateLinkPromotion.shortenedAffiliateLink : promotion.affiliateLinkPromotion.affiliateLink}
                                image={mattress.mattressImage}
                                title={mattress.name}
                                cta={promotion.affiliateLinkCta}
                                description={promotion.description}
                                key={`key-deal-${i}-${k}`} 
                                headerClass={`${ i % 2 === 0 ? 'deal-bg-1' : 'deal-bg-2'}`}
                            />
                        )
                    }
                })
            }
        }
    })

    return (
        <div className={className}>
            <div className={` ${container ? 'container mx-auto' : null} `}>
                <div className="grid gap-4 grid-cols-2 md:gap-6 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                    {promotions.map(promotion => {
                        return promotion
                    })}
                </div>
            </div>
        </div>
    )
}

export default ListDeal